import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import { Stages } from "../site-visit-consts";
import {
  appointmentDate,
  downloadFile,
  setStatusOfTimelineItem,
} from "../site-visit-utils";
import ReactGA from "react-ga4";

const PrepareForAppointmentStage = (props) => {
  const products = props.siteVisit?.products;
  const title =
    products?.length === 1 && products?.includes("EPC")
      ? "Appointment reminder"
      : "Prepare for your appointment";

  const body =
    products?.length === 1 && products?.includes("EPC") ? (
      <p>
        Just a reminder about your appointment at&nbsp;
        <strong>{appointmentDate(props.appointmentDate)}</strong>
      </p>
    ) : (
      <>
        <h4>GET YOUR PROPERTY READY FOR THE SHOOT</h4>
        <p>
          Download our home staging guide now to learn how you can prepare your
          property for the pro.
        </p>
        <p>
          Following our guide will ensure you are showcasing your property in
          the best possible light.
        </p>
        <br />
        <a
          href={downloadFile("01 Home Staging Guide.pdf")}
          download
          onClick={() =>
            ReactGA.event("download_guide_prepare_for_appointment")
          }
          className="btn btn--green"
        >
          Home Staging Guide
        </a>
      </>
    );
  return (
    <ProgressTimelineItem
      title={title}
      description="We’ll be in touch a day before your appointment"
      className={setStatusOfTimelineItem(
        Stages.PrepareForAppointment,
        props.siteVisit?.stage,
        props.hoursToAppointment
      )}
    >
      {body}
    </ProgressTimelineItem>
  );
};

export default PrepareForAppointmentStage;
