import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { OrderStages } from "./site-visit-consts";

export default function useFetch() {
  const [orderRaw, setOrderRaw] = useState(null);
  const [photographers, setPhotographers] = useState(null);
  const [order, setOrder] = useState(null);
  const [advert, setAdvert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { orderId } = useParams();

  useEffect(() => {
    fetchOrder(orderId);
  }, [orderId]);

  useEffect(() => {
    checkForPhotographers(orderRaw);
  }, [orderRaw]);

  useEffect(() => {
    fetchAdvert(orderRaw);
  }, [orderRaw]);

  useEffect(() => {
    setOrderInfo(orderRaw, photographers, advert);
  }, [photographers, orderRaw, advert]);

  const fetchOrder = async (orderId) => {
    setLoading(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "client-secret": process.env.REACT_APP_CLIENT_SECRET,
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_ORDER_SUBSCRIPTION_KEY,
      },
    };
    const url = process.env.REACT_APP_ORDER_API_URL + orderId;
    await axios
      .get(url, headers)
      .then((response) => {
        setOrderRaw(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAdvert = async (orderRaw) => {
    if (orderRaw?.AccountCode && orderRaw?.CrmOrderId) {
      setLoading(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          "client-secret": process.env.REACT_APP_CLIENT_SECRET,
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_ADVERT_SUBSCRIPTION_KEY,
        },
      };
      const url =
        process.env.REACT_APP_ADVERT_API_URL +
        orderRaw.AccountCode +
        "/" +
        orderRaw.CrmOrderId;

      await axios
        .get(url, headers)
        .then((response) => {
          setAdvert(response.data);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchPhotographer = async (id, orderLines) => {
    setLoading(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "client-secret": process.env.REACT_APP_CLIENT_SECRET,
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_PHOTOGRAPHER_SUBSCRIPTION_KEY,
      },
    };
    const url = process.env.REACT_APP_PHOTOGRAPHER_API_URL + id;
    const response = await axios.get(url, headers);

    let pro = response.data;

    pro["OrderLines"] = orderLines;
    return pro;
  };

  const checkForPhotographers = (orderRaw) => {
    setLoading(true);
    if (orderRaw && orderRaw.OrderDetails && orderRaw.OrderDetails.OrderLines) {
      const rawPhotographersIds = orderRaw.OrderDetails.OrderLines.map(
        (ol) => ol.PhotographerId
      );

      const photographersIds = [...new Set(rawPhotographersIds)].sort();
      let promises = [];

      photographersIds.forEach((id) => {
        if (id) {
          let photographerOrderLines = [];
          orderRaw.OrderDetails.OrderLines.forEach((ol) => {
            if (ol.PhotographerId === id) {
              photographerOrderLines.push(ol);
            }
          });

          promises.push(fetchPhotographer(id, photographerOrderLines));
        }
      });

      if (promises.length) {
        Promise.all(promises).then((pros) => setPhotographers(() => pros));
      }
    }
    setLoading(false);
  };

  const setOrderInfo = (orderRaw, photographers) => {
    let siteVisits = [];

    if (photographers && photographers.length) {
      photographers.forEach((p) => {
        siteVisits.push({
          stage: generateSiteVisitStage(p.OrderLines),
          photographer: p,
          appointmentDate: p.OrderLines[0].AppointmentDateTimeUtc,
          products: p.OrderLines.map((product) => product.ProductName),
        });
      });
    } else {
      siteVisits.push({
        stage: generateSiteVisitStage(orderRaw?.OrderDetails?.OrderLines),
      });
    }

    if (orderRaw) {
      
      setOrder({
        id: orderRaw.CrmOrderId,
        agencyName: getAgencyName(orderRaw.AgencyName, orderRaw.OrderNotes),
        branchName: orderRaw.BranchName,
        vendorName: orderRaw.VendorName,
        property: orderRaw.Property,
        status: orderRaw.OrderStatus,
        orderNotes: orderRaw.OrderNotes,
        orderDetails: orderRaw.OrderDetails,
        siteVisits: siteVisits,
        advert: advert?.Advert,
      });
    }
  };

  const generateSiteVisitStage = (orderLines) => {
    let stages = [];
    let statusIsValid;
    orderLines?.forEach((o) => {
      const status = o.Status.replaceAll(" ", "-").toLowerCase();

      statusIsValid = Object.values(OrderStages).some((value) => {
        return value.includes(status);
      });

      if (statusIsValid) {
        stages.push(
          Object.keys(OrderStages).find((key) =>
            OrderStages[key].includes(status)
          )
        );
      } else {
        stages.push(1);
      }
    });
    const uniqueStages = [...new Set(stages)];

    if (uniqueStages.length > 1 && Math.min(...uniqueStages) === 0) {
      return Math.max(...uniqueStages);
    } else {
      return Math.min(...uniqueStages);
    }
  };

  const getAgencyName = (agencyName, orderNotes) => {
    let editedAgencyName = agencyName
    
    if(orderNotes){
      const agentNameSplit = orderNotes.split('Agent Name:');
      if (agentNameSplit.length === 2) {
        const agentEmailSplit = agentNameSplit[1].split('Agent Email');
        if(agentEmailSplit.length > 1){
          editedAgencyName = agentEmailSplit[0];
        }
      }
    } 
    return editedAgencyName.trim();
  }

  return [loading, order, error];
}

