import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertTitle, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default (props) => {
  const [open, setOpen] = useState(false);
  const handleReload = () => {
    window.location.reload(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      {!props.hideReload ? (
        <Button color="error" size="small" onClick={handleReload}>
          Reload
        </Button>
      ) : (
        ""
      )}
      {!props.hideCloseBtn ? (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : (
        ""
      )}
    </React.Fragment>
  );
  useEffect(() => {
    if (props.message) {
      setOpen(true);
    }
  }, [props.message]);
  return (
    <Snackbar open={open}>
      <Alert severity="error" action={action}>
        <AlertTitle>Error</AlertTitle>
        {props.message?.message}
      </Alert>
    </Snackbar>
  );
};
