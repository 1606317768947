import Photographer from "../../../components/Photographer/Photographer";
import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import { Stages } from "../site-visit-consts";
import { setStatusOfTimelineItem } from "../site-visit-utils";

const ProAllocatedStage = (props) => {
  const photographer = props.siteVisit?.photographer ? (
    <Photographer
      photographer={props.siteVisit?.photographer}
      analytics={props.analytics}
    />
  ) : (
    ""
  );
  return (
    <ProgressTimelineItem
      title="Pro accepted"
      description="Your pro’s details will be available here"
      className={setStatusOfTimelineItem(
        Stages.ProAllocated,
        props.siteVisit?.stage
      )}
    >
      <h4>ORDER ALLOCATION SUCCESSFUL</h4>
      <p>
        We have successfully allocated a pro to capture content for&nbsp;
        <strong>{props.propertyDetails}</strong>. The details of the pro can be
        seen below.
      </p>
      <br />
      {photographer}
      <h4>What’s next?</h4>
      <p>
        Your pro will be in contact with you shortly to arrange an appointment.
      </p>
      <p>
        Note: There may be a slight delay in updating the status on this page
        after receiving your next SMS. If this is the case please&nbsp;
        <a href="" onClick={() => window.location.reload()}>
          click here
        </a>{" "}
        to refresh the page after 1 - 2 minutes.
      </p>
    </ProgressTimelineItem>
  );
};

export default ProAllocatedStage;
