import PropTypes from "prop-types";
import "./ProgressTracker.css";

function ProgressTracker(props) {
  const stageImagePath = require("./images/stage-" +
    props.stage?.toString() +
    ".svg");

  const content =
    props.orderStatus === "Cancelled" ? (
      <h3>THIS ORDER HAS BEEN CANCELLED</h3>
    ) : (
      <img
        src={stageImagePath}
        className="progress-tracker__stage"
        alt="Order progress tracker"
        loading="lazy"
      />
    );
  return (
    <div className="progress-tracker">
      <div className="wrapper">{content}</div>
    </div>
  );
}

ProgressTracker.propTypes = {
  stage: PropTypes.number,
};

export default ProgressTracker;
