import "./Photographer.css";
import { RatingStar } from "rating-star";
import ReactGA from "react-ga4";

function Photographer(props) {
  const company = props.photographer?.CompanyName ? (
    <p className="photographer-info__company">
      {props.photographer?.CompanyName}
    </p>
  ) : (
    ""
  );

  const names = props.photographer?.ContactDetails ? (
    <h3 className="photographer-info__name">
      {props.photographer?.ContactDetails.FirstName}&nbsp;
      {props.photographer?.ContactDetails.LastName.slice(0, 1)}.
    </h3>
  ) : (
    ""
  );

  const avatar = props.photographer?.ProfilePictureUrl ? (
    <img
      className="photographer-info__avatar"
      src={props.photographer?.ProfilePictureUrl}
      alt="The Photographer"
    />
  ) : (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="photographer-info__avatar"
    >
      <rect width="65" height="65" rx="32.5" fill="#D9D9D9" />
      <path
        d="M32 33C36.0501 33 39.3334 29.7168 39.3334 25.6667C39.3334 21.6166 36.0501 18.3334 32 18.3334C27.9499 18.3334 24.6667 21.6166 24.6667 25.6667C24.6667 29.7168 27.9499 33 32 33Z"
        fill="#A7A7A7"
      />
      <path
        d="M32 36.6666C20.8166 36.6666 17.3333 44 17.3333 44V47.6666H46.6666V44C46.6666 44 43.1833 36.6666 32 36.6666Z"
        fill="#A7A7A7"
      />
    </svg>
  );

  const rating = props.photographer?.Rating ? (
    <div className="photographer-info__row photographer-info__rating">
      <p>Pro Rating:</p>
      <RatingStar
        maxScore={5}
        id={props.photographer?.PhotographerId}
        rating={parseFloat(props.photographer?.Rating)}
      />
    </div>
  ) : (
    ""
  );

  const contact =
    props.photographer?.ContactDetails &&
    props.photographer?.ContactDetails.Mobile ? (
      <div className="photographer-info__row photographer-info__contact">
        <p>Contact:</p>
        <p>{props.photographer?.ContactDetails.Mobile}</p>
      </div>
    ) : (
      ""
    );

  const callButton =
    props.photographer?.ContactDetails &&
    props.photographer?.ContactDetails.Mobile ? (
      <>
        <a
          href={"tel:" + props.photographer?.ContactDetails.Mobile}
          className="btn btn--green photographer-info__call-now"
          onClick={() => ReactGA.event("call_pro")}
        >
          Call Now
        </a>
      </>
    ) : (
      ""
    );
  return (
    <div className="photographer-info">
      <div className="photographer-info__row">
        {avatar}
        <div className="photographer-info__details">
          {names}
          {company}
        </div>
      </div>
      {rating}
      {contact}
      {callButton}
    </div>
  );
}

export default Photographer;
