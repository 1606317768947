import React from "react";
import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import {
  createProductsList,
  isNotOnlyEPC,
  setStatusOfTimelineItem,
} from "../site-visit-utils";
import { Stages } from "../site-visit-consts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Advert from "../../../components/Advert/Advert";

const WelcomeStage = (props) => {
  const products =
    props.siteVisit && props.siteVisit?.products
      ? createProductsList(props.siteVisit?.products)
      : props.orderLines
      ? createProductsList(props.orderLines)
      : "";

  const advert = props.advert ? (
    <Advert advert={props.advert} GAEventName="Click-B2C_advert_Intro" />
  ) : (
    ""
  );

  const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <ProgressTimelineItem
      title={"FocalAgent x " + props.agencyName}
      className={setStatusOfTimelineItem(
        Stages.Welcome,
        props.siteVisit?.stage
      )}
    >
      <h4>Who we are</h4>
      <p>
        We are &nbsp;
        <a href="https://focalagent.com" target="_blank" rel="noreferrer">
          FocalAgent
        </a>
        , a visual content partner working on behalf of&nbsp;
        <strong>{props.agencyName}</strong>. We use a nationwide network of
        professional photographers and EPC assessors to capture the visual
        content needed to market your property. We often refer to these
        professionals as pros, so you will hear that terminology throughout your
        journey with us.
      </p>
      <h4>WHAT WILL WE BE DOING?</h4>
      <p>
        We have been instructed to visit&nbsp;
        <strong className="capitalize">{props.propertyDetails}</strong>. We'll
        be producing the following products to help your property be marketed in
        the best light:
      </p>
      <ul>{products}</ul>
      {advert}
      <h4>What’s next?</h4>
      <p>
        We'll send you a text message at various stages throughout the journey.
        In the meantime, you can track progress using this page.
      </p>
      {isNotOnlyEPC(
        props.siteVisit?.products,
        <>
          <h4>HERE'S A PROPERTY WE RECENTLY HELPED MARKET</h4>
          <p>
            Your order will be assigned to our award-winning network of over
            1000+ pros, where your property will be lovingly captured - just
            like this one!
          </p>
          <br />
          <Slider {...sliderSettings}>
            <img
              src="/slides/professional-photography-enhanced-front-of-house.jpeg"
              alt="Professional photography enhanced front of house"
              loading="lazy"
            />
            <img
              src="/slides/professional-photography-enhanced-dining-room.jpeg"
              alt="Professional photography enhanced dining room"
              loading="lazy"
            />
            <img
              src="/slides/professional-photography-enhanced-kitchen.jpeg"
              alt="Professional photography enhanced kitchen"
              loading="lazy"
            />
            <img
              src="/slides/professional-photography-enhanced-bedroom.jpeg"
              alt="Professional photography enhanced bedroom"
              loading="lazy"
            />
          </Slider>
        </>
      )}
    </ProgressTimelineItem>
  );
};

export default WelcomeStage;
