import "./Advert.css";
import ReactGA from "react-ga4";

function Advert(props) {
  const title = props.advert?.Text ? (
    <h3 className="advert__title">{props.advert?.Text}</h3>
  ) : (
    ""
  );
  const image = props.advert?.Image ? (
    <img
      className="advert__image"
      src={props.advert?.Image}
      alt="Advert image"
    />
  ) : (
    ""
  );
  const url = props.advert?.Url ? (
    <a
      href={props.advert?.Url}
      target="_blank"
      onClick={() => ReactGA.event(props.GAEventName)}
      className="btn btn--red"
    >
      Explore Offer
    </a>
  ) : (
    ""
  );

  return (
    <div className="advert">
      <div class="advert__ribbon">
        <span>Exclusive Discount</span>
      </div>
      {title}
      {image}
      {url}
    </div>
  );
}

export default Advert;
