import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import "./ProgressTimeline.css";

function ProgressTimelineItem(props) {
  const [height, setHeight] = useState(0);

  function toggleBody() {
    setHeight(height === 0 ? "auto" : 0);
  }

  useEffect(() => {
    if (props.className && props.className.indexOf("current") !== -1) {
      setHeight("auto");
    } else {
      setHeight(0);
    }
  }, [props.className]);

  return (
    <div
      className={
        "progress-timeline__stage" +
        (height !== 0 ? " expanded" : "") +
        " " +
        props.className
      }
    >
      <button className="progress-timeline__stage-header" onClick={toggleBody}>
        <div className="progress-timeline__stage-header-title">
          <div className="progress-timeline__stage-header-title-text">
            {props.title}
            <span className="progress-timeline__stage-header-dot">.</span>
          </div>

          <div className="progress-timeline__stage-header-tags">
            {props.tags}
          </div>
        </div>
        <span className="progress-timeline__stage-header-description">
          {props.description}
        </span>
      </button>
      <AnimateHeight
        duration={500}
        easing="cubic-bezier(0.16, 1, 0.3, 1)"
        height={height}
      >
        <div className="progress-timeline__stage-body">{props.children}</div>
      </AnimateHeight>
    </div>
  );
}

ProgressTimelineItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ProgressTimelineItem;
