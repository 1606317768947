import "./App.css";
import { RouterProvider } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Main from "./pages/site-visit/siteVisit";
import PageNotFound from "./pages/page-not-found/pageNotFound";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const routesDefinitions = createRoutesFromElements(
  <Route>
    <Route path="/:orderId" element={<Main />}></Route>
    <Route path="*" element={<PageNotFound />} />
  </Route>
);

const router = createBrowserRouter(routesDefinitions);

export default () => {
  return <RouterProvider router={router} />;
};
