import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

function PageNotFound() {
  return (
    <ErrorMessage
      title="Order not found"
      message="Please provide an order id"
    />
  );
}

export default PageNotFound;
