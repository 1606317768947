import React from "react";
import "./ProgressTimeline.css";

function ProgressTimeline(props) {
  return (
    <React.Fragment>
      <div className="progress-timeline">
        <div className="wrapper">{props.children}</div>
      </div>
    </React.Fragment>
  );
}
export default ProgressTimeline;
