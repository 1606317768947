const SiteVisitTabs = (props) => {
  return (
    <div className="tabs__header">
      <div className="wrapper">
        <div className="tabs__wrapper">
          <button
            className={"tabs__tab" + (props.currentTab === 0 ? " active" : "")}
            onClick={() => props.handleCurrentTab(0)}
          >
            Appointment 1
          </button>
          <button
            className={"tabs__tab" + (props.currentTab === 1 ? " active" : "")}
            onClick={() => props.handleCurrentTab(1)}
          >
            Appointment 2
          </button>
        </div>
      </div>
    </div>
  );
};

export default SiteVisitTabs;
