import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import { Stages } from "../site-visit-consts";
import { setStatusOfTimelineItem } from "../site-visit-utils";

const CompletedStage = (props) => {
  return (
    <ProgressTimelineItem
      title="How did we do?"
      description="After your appointment, we’d love to hear how we did!"
      className={setStatusOfTimelineItem(
        Stages.Completed,
        props.siteVisit?.stage
      )}
    >
      <p>
        We have completed the site visit and your Estate Agent will be in
        contact with you soon.
      </p>
      <p>
        Your opinion matters to us, so any feedback you could give us via the
        SMS survey link we sent you would be much appreciated. Thank you for
        using our services.
      </p>
    </ProgressTimelineItem>
  );
};
export default CompletedStage;
