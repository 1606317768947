import "./Footer.css";
import ReactGA from "react-ga4";

function Footer(props) {
  return (
    <footer className="app-footer">
      <div className="wrapper">
        <div className={props.orderNotes ? 'vendor-agency-details vendor-agency-details__center-content' : 'vendor-agency-details'}>
          <div className="vendor-agency-details__left">
            <p>
              {props.orderNotes ? '' : props.agencyName + ", " + props.branchName}
            </p>
          </div>
          <div className="vendor-agency-details__right">
            <img src="/partnered-with-focal.svg" alt="FocalAgent logo" />
          </div>
        </div>
      </div>
      <div className="wrapper feedback-section">
        <a
          href="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=f-CA9n5ajEOFU3Uq54phC4m_oGLBGA9NsVQJGezm5jBUN1BMV0dCWFJGQkVURDUyNDc1Slc1VDZVUC4u"
          target="_blank"
          onClick={() => ReactGA.event("feedback_link")}
        >
          Give feedback here.
        </a>
      </div>
    </footer>
  );
}

export default Footer;
