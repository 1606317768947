export const OrderStages = {
  0: "cancelled",
  1: "pending, new",
  2: "awaiting-pro-acceptance, pro-not-allocated, awaiting-pro-allocation",
  3: "awaiting-appointment-details",
  4: "appointment-booked", // There is not stage 5, since stage 5 depends on stage 4 and 24h logic
  6: "awaiting-assets, complete, ready-for-fulfillment, awaiting-sample-selection, samples-selected, qa-approved, qa, in-production, being-enhanced, awaiting-finals, awaiting-approval, awaiting-sv-url",
};

export const Stages = {
  Welcome: 1,
  AssigningPro: 2,
  ProAllocated: 3,
  AppointmentDetails: 4,
  PrepareForAppointment: 5,
  Completed: 6,
};
