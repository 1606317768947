import "./Header.css";

function Header(props) {
  const errorIcon = require("./images/error.svg").default;
  const orderCancelled =
    props.order?.status === "Cancelled" ? (
      <div className="message message--error">
        <div className="wrapper">
          <img src={errorIcon} loading="lazy" alt="Error icon" />
          <h3>THIS ORDER HAS BEEN CANCELLED</h3>
          <p>If you have any question, speak to your agent</p>
        </div>
      </div>
    ) : (
      ""
    );
  return (
    <header className="app-header">
      {orderCancelled}
      <div className="wrapper">
        <p>
          <strong>Dear {props.order?.vendorName},</strong>
        </p>
        <div className="vendor-agency-details">
          <div className="vendor-agency-details__left">
            <p>
              We’ve been instructed by&nbsp;
              <strong>
                {props.order?.agencyName}, {props.order?.orderNotes ? '' : props.order?.branchName}
              </strong>
              &nbsp; to visit your property:
            </p>
            <ul className="color--orange">
              <li>
                <strong>{props.order?.property?.AddressLine1}</strong>
              </li>
              <li>
                <strong>{props.order?.property?.AddressLine2}</strong>
              </li>
              <li>
                <strong>{props.order?.property?.AddressTown}</strong>
              </li>
              <li>
                <strong>{props.order?.property?.AddressCounty}</strong>
              </li>
              <li>
                <strong>{props.order?.property?.AddressPostcode}</strong>
              </li>
            </ul>
          </div>
          <div className="vendor-agency-details__right"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
