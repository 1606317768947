import useFetch from "./useFetch";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import SiteVisitTabs from "./site-visit-tabs/SiteVisitTabs";
import React, { useState } from "react";
import SiteVisitStages from "./site-visit-stages";
import SnackbarBanner from "../../components/SnackbarBanner/SnackbarBanner";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

export default () => {
  const [loading, order, error] = useFetch();
  const [currentTab, setCurrentTab] = useState(0);
  const tabs =
    order?.siteVisits.length > 1 ? (
      <SiteVisitTabs handleCurrentTab={setCurrentTab} currentTab={currentTab} />
    ) : (
      ""
    );

  return error ? (
    <ErrorMessage
      title="Your order's being processed"
      message="If you're not automatically redirected in the next few minutes, refresh this page."
      refreshButton
      reload
    />
  ) : loading || !order ? (
    <Loader />
  ) : (
    <div className="app">
      <Header order={order} />
      {tabs}
      <SiteVisitStages
        order={order}
        siteVisit={order?.siteVisits[currentTab]}
      />
      <Footer orderNotes={order?.orderNotes} agencyName={order?.agencyName} branchName={order?.branchName} />
      <SnackbarBanner message={error} />
    </div>
  );
};
