import React from "react";
import ProgressTimeline from "../../../components/ProgressTimeline/ProgressTimeline";
import ProgressTracker from "../../../components/ProgressTracker/ProgressTracker";
import { hoursToAppointment, tidyUpAddress } from "../site-visit-utils";
import { Stages } from "../site-visit-consts";
import WelcomeStage from "./WelcomeStage";
import AssigningProStage from "./AssigningProStage";
import ProAllocatedStage from "./ProAllocatedStage";
import AppointmentDetailsStage from "./AppointmentDetailsStage";
import PrepareForAppointmentStage from "./PrepareForAppointmentStage";
import CompletedStage from "./CompletedStage";

export default (props) => {
  const siteVisit = props.siteVisit;
  const order = props.order;

  const propertyDetails = tidyUpAddress(order?.property);
  const appointmentDate = siteVisit?.appointmentDate;
  const hrsToAppointment = hoursToAppointment(appointmentDate);

  return (
    <React.Fragment>
      <ProgressTracker
        orderStatus={order?.status}
        stage={
          siteVisit?.stage === Stages.AppointmentDetails &&
          hrsToAppointment <= 24
            ? Stages.PrepareForAppointment
            : siteVisit?.stage
        }
      />
      <ProgressTimeline>
        <WelcomeStage
          siteVisit={siteVisit}
          advert={order?.advert}
          agencyName={order?.agencyName}
          propertyDetails={propertyDetails}
          orderLines={order?.orderDetails?.OrderLines}
        />
        <AssigningProStage
          siteVisit={siteVisit}
          analytics={props.analytics}
          advert={order?.advert}
        />
        <ProAllocatedStage
          siteVisit={siteVisit}
          propertyDetails={propertyDetails}
          analytics={props.analytics}
        />
        <AppointmentDetailsStage
          siteVisit={siteVisit}
          hoursToAppointment={hrsToAppointment}
          appointmentDate={appointmentDate}
        />
        <PrepareForAppointmentStage
          siteVisit={siteVisit}
          hoursToAppointment={hrsToAppointment}
          appointmentDate={appointmentDate}
          analytics={props.analytics}
        />
        <CompletedStage siteVisit={siteVisit} />
      </ProgressTimeline>
    </React.Fragment>
  );
};
