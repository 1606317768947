import React from "react";
import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import { Stages } from "../site-visit-consts";
import { appointmentDate, setStatusOfTimelineItem } from "../site-visit-utils";

const AppointmentDetailsStage = (props) => {
  return (
    <ProgressTimelineItem
      title="Appointment details"
      description="Details will follow once your pro has been in contact"
      className={setStatusOfTimelineItem(
        Stages.AppointmentDetails,
        props.siteVisit?.stage,
        props.hoursToAppointment
      )}
    >
      <p>
        Appointment Details:&nbsp;
        <strong>{appointmentDate(props.appointmentDate)}</strong>
      </p>
      <p>
        Your appointment has been scheduled. If you believe this is incorrect,
        please contact the pro using the details above.
      </p>
    </ProgressTimelineItem>
  );
};

export default AppointmentDetailsStage;
