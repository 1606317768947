import React from "react";
import ProgressTimelineItem from "../../../components/ProgressTimeline/ProgressTimelineItem";
import { Stages } from "../site-visit-consts";
import ReactGA from "react-ga4";
import Advert from "../../../components/Advert/Advert";

import {
  downloadFile,
  isNotOnlyEPC,
  setStatusOfTimelineItem,
} from "../site-visit-utils";

const AssigningProStage = (props) => {
  const products = props.siteVisit?.products;
  const advert = props.advert ? (
    <>
      <br />
      <Advert
        advert={props.advert}
        GAEventName="Click-B2C_advert__assigning_pro"
      />
    </>
  ) : (
    ""
  );

  return (
    <ProgressTimelineItem
      title="Assigning a Pro"
      description="The process of assigning pro begins here"
      tags={isNotOnlyEPC(products, <span>Download Guide</span>)}
      className={setStatusOfTimelineItem(
        Stages.AssigningPro,
        props.siteVisit?.stage
      )}
    >
      <p>
        Our systems are in the process of assigning a local pro to your
        property.
      </p>

      {isNotOnlyEPC(
        products,
        <>
          <h4>GET YOUR PROPERTY READY FOR THE SHOOT</h4>
          <p>
            Download our home staging guide now to learn how you can prepare
            your property for the pro.
          </p>
          <p>
            Following our guide will ensure you are showcasing your property in
            the best possible light.
          </p>
          <br />
          <a
            href={downloadFile("01 Home Staging Guide.pdf")}
            onClick={() => ReactGA.event("download_guide_assigning_pro")}
            download
            className="btn btn--green"
          >
            Home Staging Guide
          </a>
        </>
      )}
      {advert}
      <h4>What’s next?</h4>
      <p>
        You will receive a SMS update and an update on your portal as soon at
        the pro has accepted the job.
      </p>
      <p>
        Note: There may be a slight delay in updating the status on this page
        after receiving your next SMS. If this is the case please{" "}
        <a href="" onClick={() => window.location.reload()}>
          click here
        </a>{" "}
        to refresh the page after 1 - 2 minutes.
      </p>
    </ProgressTimelineItem>
  );
};

export default AssigningProStage;
