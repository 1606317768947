import { Stages } from "./site-visit-consts";

const { DateTime } = require("luxon");

export const tidyUpAddress = (property) => {
  return (
    (property?.AddressLine1 ? property?.AddressLine1 + " " : "") +
    (property?.AddressLine2 ? property?.AddressLine2 + ", " : "") +
    (property?.AddressTown ? property?.AddressTown + ", " : "") +
    (property?.AddressPostcode ? property?.AddressPostcode : "")
  );
};

export const hoursToAppointment = (appointmentDate) => {
  if (appointmentDate) {
    const now = DateTime.now().setZone("Europe/London");
    const appDate = DateTime.fromISO(appointmentDate, { zone: "UTC" }).setZone(
      "Europe/London"
    );

    return appDate.diff(now, "hours").toObject().hours;
  } else {
    return "";
  }
};

export const appointmentDate = (date) => {
  if (date) {
    return DateTime.fromISO(date, { zone: "UTC" })
      .setZone("Europe/London")
      .toFormat("dd/LL/yyyy HH:mm");
  } else {
    return "";
  }
};

export const downloadFile = (file) => {
  return process.env.PUBLIC_URL + "/downloads/" + file;
};

export const setStatusOfTimelineItem = (
  defaultStage,
  currentStage,
  hoursToAppointment
) => {
  if (
    hoursToAppointment <= 24 &&
    defaultStage === Stages.PrepareForAppointment &&
    currentStage === Stages.AppointmentDetails
  ) {
    return "progress-timeline__stage--current";
  } else if (
    currentStage === Stages.AppointmentDetails &&
    hoursToAppointment <= 24
  ) {
    return "progress-timeline__stage--complete";
  } else if (currentStage > defaultStage) {
    return "progress-timeline__stage--complete";
  } else if (currentStage === defaultStage) {
    return "progress-timeline__stage--current";
  } else {
    return "";
  }
};

export const isNotOnlyEPC = (products, content) => {
  return products?.length === 1 && products?.includes("EPC") ? (
    ""
  ) : (
    <>{content}</>
  );
};

export const createProductsList = (products) => {
  return products
    .map((product) => {
      product = product.ProductName ? product.ProductName : product;
      return (
        <li key={product}>
          {product === "Photography" ? "Professional Photography" : product}
        </li>
      );
    })
    .sort()
    .reverse();
};
