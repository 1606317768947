import React, { useEffect } from "react";
import "./ErrorMessage.css";

const ErrorMessage = (props) => {
  const refreshButton = props.refreshButton ? (
    <button className="btn btn--green" onClick={() => window.location.reload()}>
      refresh this page
    </button>
  ) : (
    ""
  );
  useEffect(() => {
    if (props.reload) {
      setTimeout(() => window.location.reload(), 60000);
    }
  }, []);
  return (
    <div className="error-message__wrapper">
      <div className="error-message">
        <img
          src="/focal-logo.svg"
          className="error-message__logo"
          alt="FocalAgent logo"
        />
        <div>
          <h1>{props.title}</h1>
          <p>{props.message}</p>
        </div>
        {refreshButton}
      </div>
    </div>
  );
};

export default ErrorMessage;
